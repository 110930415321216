import React from 'react';

import { formatCurrency } from 'src/utils';

import { Body, Container, NonReconText, ReconText, Title } from './styles';

function ExpensesTabTotals({ totals, grossColor }) {
  const { total_expenses } = totals;

  return (
    <Container>
      <Title>Expenses</Title>
      <Body>
        <ReconText color={grossColor}>
          {formatCurrency(total_expenses.gross.reconciled)}
        </ReconText>
        <NonReconText>
          {formatCurrency(total_expenses.gross.non_reconciled)}
        </NonReconText>
      </Body>
    </Container>
  );
}

export default ExpensesTabTotals;
