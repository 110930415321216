import React from 'react';

import { formatCurrency } from 'src/utils';

import {
  Body,
  BodyGross,
  Container,
  Content,
  ContentTitle,
  NonReconText,
  ReconText,
  TaxPercentage,
  Title,
} from './styles';

function IncomesTabTotals({ totals }) {
  const { total_incomes } = totals;

  const reconGross = total_incomes?.gross?.reconciled || 0;
  const taxRecon = total_incomes?.tax?.reconciled || 0;
  const taxPercentage = reconGross !== 0 ? (taxRecon / reconGross) * 100 : 0;

  return (
    <Container>
      <Body isFirstColumn>
        <Title isFirstColumn>Income</Title>
        <Content isFirstColumn>
          <ContentTitle>Recon</ContentTitle>
          <ContentTitle>Non-recon</ContentTitle>
        </Content>
      </Body>
      <BodyGross>
        <Title>Gross</Title>
        <Content>
          <ReconText>
            {formatCurrency(total_incomes.gross.reconciled)}
          </ReconText>
          <NonReconText>
            {formatCurrency(total_incomes.gross.non_reconciled)}
          </NonReconText>
        </Content>
      </BodyGross>
      <Body>
        <Title>Net</Title>
        <Content>
          <ReconText>{formatCurrency(total_incomes.net.reconciled)}</ReconText>
          <NonReconText>
            {formatCurrency(total_incomes.net.non_reconciled)}
          </NonReconText>
        </Content>
      </Body>
      <Body>
        <Title>Tax</Title>
        <Content>
          <ReconText>{formatCurrency(total_incomes.tax.reconciled)}</ReconText>
          <NonReconText>
            {formatCurrency(total_incomes.tax.non_reconciled)}
            <TaxPercentage>{Math.round(taxPercentage)}%</TaxPercentage>
          </NonReconText>
        </Content>
      </Body>
    </Container>
  );
}

export default IncomesTabTotals;
