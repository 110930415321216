import styled from 'styled-components';

type Props = {
  color?: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 30px;
  border-radius: 6px;
  margin-left: 12px;
  position: relative;
  bottom: 9px;
  height: 93px;
`;

export const Title = styled.span`
  font-size: 14px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

export const ReconText = styled.span<Props>`
  font-size: 16px;
  color: ${(props) => props.color};
`;

export const NonReconText = styled.span`
  font-size: 14px;
  color: #929292;
`;
