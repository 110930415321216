import styled from 'styled-components';

type Props = {
  active: boolean;
  status: string;
  title: string;
};

const getContainerColor = (props: Props) => {
  if (props.title === 'Identify' && props.status === 'Denied') {
    return props.theme.colors.red.bittersweet;
  } else if (props.title === 'Identify' && props.status === 'Approved') {
    return '#57e4cd';
  } else if (props.title === 'Submitted' && props.status === 'error') {
    return props.theme.colors.red.bittersweet;
  } else if (props.title === 'Pie Check' && props.status === 'pending') {
    return props.theme.colors.red.bittersweet;
  } else if (props.title === 'Submitted' && props.status === 'not_started') {
    return '#D8D8D8';
  } else if (props.active) {
    return '#57e4cd';
  } else {
    return '#D8D8D8';
  }
};

export const CardContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 82px;
  height: 30px;
  background-color: ${getContainerColor};
  border-radius: 5px;
  padding: 2px;
`;

export const CardIcon = styled.img`
  height: 22px;
  width: 22px;
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .title,
  .subtitle {
    font-size: 8px;
    font-weight: 600;
  }
`;

export const Container1 = styled.div``;
