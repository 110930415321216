import React from 'react';

import { formatDate } from 'src/utils';

import { CardContainer, CardIcon, CardDetails } from './styles';

type Props = {
  cardIcon: string;
  title: string;
  subtitle: string;
  active: boolean;
  status: string;
  value: string;
};

function CustomerProgressPie({
  cardIcon,
  title,
  subtitle,
  active,
  status,
  value,
}: Props) {
  const handleCardValue = () => {
    if (title === 'Identify' && status === 'Denied') return formatDate(value);
  };

  return (
    <CardContainer active={active} status={status} title={title}>
      <CardIcon src={cardIcon} alt="card icon" />
      <CardDetails>
        <span className="title">{title}</span>
        <span className="subtitle">{subtitle}</span>
        <span className="subtitle">{handleCardValue()}</span>
      </CardDetails>
    </CardContainer>
  );
}

export default CustomerProgressPie;
