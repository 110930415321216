import styled from 'styled-components';

type Props = {
  isFirstColumn?: boolean;
};

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  position: relative;
  bottom: 9px;
  height: 93px;
`;

export const Title = styled.span<Props>`
  color: ${(props) => (props.isFirstColumn ? '191424' : '#929292')};
`;

export const Body = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => !props.isFirstColumn && '0 60px 0 0'};
`;

export const BodyGross = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
`;

export const Content = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => !props.isFirstColumn && 'center'};
  margin-top: 10px;
  position: relative;
  top: ${(props) => props.isFirstColumn && '8px'};
  left: ${(props) => props.isFirstColumn && '4px'};
`;

export const ContentTitle = styled.div`
  color: #afafaf;
  font-size: 11px;
`;

export const ReconText = styled.span`
  font-size: 16px;
`;

export const NonReconText = styled.span`
  font-size: 14px;
  color: #929292;
`;

export const TaxPercentage = styled.div`
  position: absolute;
  right: -60px;
  bottom: 21px;
  font-size: 16px;
  color: #209782;
`;
