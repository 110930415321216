import styled from 'styled-components';

type Props = {
  background?: string;
  color?: string;
  selected?: boolean;
};

export const DetailsNameContainer = styled.div`
  display: flex;
  flex-direction: column;

  .details-item {
    display: flex;
    align-items: center;
  }
`;

export const SubscriptionStatus = styled.span<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: ${(props) => props.background};
  margin-left: 5px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
`;

export const StatusInfoContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  width: 84%;
`;

export const StatusInfoPie = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #191424;
  border-radius: 46px;
  padding: 6px 14px;
`;

export const ActionsStatusButton = styled.div`
  display: flex;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 50%;
  background-color: #57e4cd;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;

  .text {
    position: relative;
    bottom: 6px;
  }
`;

export const TaxBalance = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  width: 100%;
`;

export const AgentPictureContainer = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  left: 5px;
`;

export const AgentPicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const AgentName = styled.span`
  display: block;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;

  overflow: hidden;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CurrentStatusContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  .selected {
    background-color: #191424;
    color: #fff;
    border: none;
  }
`;

export const StatusButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #191424;
  height: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 48px;
  padding: 10px;
`;

export const SectionButton = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button<Props>`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  height: 40px;
  width: 102px;
  border: none;
  border-radius: 48px;
  font-size: 18px;
  font-weight: 600;
`;

export const ModalTitle = styled.span`
  font-weight: 600;
  font-size: 20px;
`;

export const CustomerProgressSection = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

export const ServiceTypeContainer = styled.div`
  display: flex;
`;
